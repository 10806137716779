::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  /*background-color:  hsla(0, 0%, 42%, 0.29);*/
  background-color: lightgray;
  /*opacity: 0.5;*/
  border-radius: 100px;
}

.ps-sidebar-root {
  border:0px !important;
}
.ps-sidebar-container {
  background-color: #292b2c !important;
  color: lightgray;
  border-right: 1px solid gray;
}
.ps-menu-button{
  background-color: #292b2c !important;
}
.ps-menu-button:hover{
  background-color: white !important;
  color:gray !important;
}


.tableCustom td span {
  width: 100%;
  /*display:inline-block;*/

  /*background-color: red;*/
  /*word-wrap: break-word;*/
}

.tableCustom {
  border: 0px !important;
}

.tableCustom thead {
  background-color: #292b2c;
}
.tableCustom th {
  color:white;
  border:0px;
}

.invalidTR {
  background-color: #d9534f;
}

.modal-dialog{
  position: fixed;
  top:150px;
  padding-bottom: 150px;
}
.bxbModal {
  /*width:100%;*/
  /*height:100%;*/
 max-width: 1200px;
}
.bxbModal .btn{
  min-width: 150px;
}

.contentModal {
  /*width:100%;*/
  /*height:100%;*/
  min-width: 70vw;
  min-height: 70vh;
}

.bxbModal{
  max-width: 1200px !important;
}

._bxbModal{
  min-width: 1500px !important;
}

.detailTable th {
  background-color: #292b2c;
  color: white;
  padding: 10px;
}

.chgBackCol td{
  text-align: right;
}

.bxbDetail td {
  padding:0px;
  /*background-color: #292b2c;*/
}

.detailTable tr {
  /*border-bottom: 1px solid lightgray;*/
  /*text-align: right;*/
  /*  padding-right: 30px;*/
}
.detailTable td {
  padding: 10px;
}

._spinner {
  z-index: 99999;
  background-color: rgba(255,255,255,0.6);
  position: absolute;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /*flexW*/
  align-items: center;
  justify-content: center;
}
#__react-alert__ {
  position: fixed !important;
  z-index: 999999999999 !important;
}

#__react-alert__ div div div {
  border-radius: 15px !important;
  width: auto !important;
  padding: 20px !important;
  border: 2px solid white !important;
  /*height: 200px;*/
}

#wrapDiv {
  /*background-color: #AD310B;*/
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}

.chgBackCol {
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}

/*.tableCustom tbody{*/
/*  overflow: scroll !important;*/
/*}*/



.row {
  --bs-gutter-x:0 !important;
}

.tableCustom tbody tr:hover{
  font-weight: bold;
  background-color: lightgray !important;
}
.tableCustom tr {
  border-color: gray;
  background-color: transparent;
  /*border:0px !important;*/
}

.tableCustom th {
  /*background-color: #ebedf7;*/
  border:0px;
  border-bottom: 1px solid #ebedf7;
  font-size: 15px !important;
  height: 50px;
}
/*.tableCustom td {*/
/*  !*border:0px;*!*/
/*  !*padding:0px !important;*!*/
/*}*/

/*.procTable input{*/
/*  height: 40px;*/
/*  !*max-width: 300px;*!*/
/*  !*background-color:   #f5f5f5 !important;*!*/
/*  !*border:0;*!*/
/*}*/
.form-switch .form-check-input {
  cursor: pointer!important;
  height: 30px;
  width: 60px !important;
}

._searchPanel input{
  height: 50px;
}
._searchPanel .btn{
  height: 50px;
  min-width: 150px;
}
.my-custom-class Button{
  border: 1px solid black;
  min-width:150px;
}

.react-bs-table-tool-bar {
  margin: 0px !important;
}
.react-bs-table-tool-bar .col-xs-6{
  width: 100%;
}
.user .react-bs-table-container{
  min-width: 1400px;
  width: 100%;
}
.tableCustom {
  width:100%;
  border: 0px !important;
}
.react-bs-table-tool-bar {
  background-color: #292b2c;
  /*width :100% !important;*/
  padding:0px 20px;
}

.tableCustom .invalidTR{
  /*color: white;*/
  background-color: #d9534f !important;
}

.procTable input{
  height: 50px;
  background-color: white !important;
  font-weight: bold;
  font-size: 20px;
  /*color:white*/
}


.procTable{
  /*max-width: 1024px;*/
  /*background-color: #292b2c;*/
  /*width:70%;*/
  /*background-color: #F2F5F8;*/
  width:100%;
  height: fit-content;
}
.bxbTable th{
  height: 50px !important;
}
.bxbTable td{
  font-size: 30px;
  font-weight: 900;
  text-align: center;
}
.procTable th{
  /*height: 60px !important;*/
  background-color: #ebedf7;
  /*padding:15px;*/
  height: 60px;
  width:10% !important;
  font-weight: normal;
  /*font-size: 16px;*/
  text-align: center;
  border: 1px solid lightgray;
}
.procTable td{
  /*width: 25%;*/
  /*max-width: 15vw;*/
  border: 1px solid lightgray;
  padding:0px 15px;
}

.react-bs-table-pagination {
  width:98% !important;
  /*justify-content: right;*/
  /*padding-left: 10px;*/
  color:black;
}
.react-bs-table-pagination li a{
  color: black;
}
/*.page-item a{*/
/*  border: 1px solid lightgray !important;*/
/*  background-color: #1F43A8;*/
/*}*/
.react-bs-table-pagination .active a{
  border: 1px solid lightgray !important;
  background-color: #292b2c !important;
  color:white !important;
}
.react-bs-table-pagination a:focus{
  box-shadow: none !important;
  color:black;
}

#pageDropDown {
  display: none;
}


.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 250px;
  height: 40px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input::placeholder {
  font-weight: normal;
  /*font-size: px;*/
}


.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 300px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}


.bagHover:hover{
  color: black;
  background-color: lightgrey;
}

